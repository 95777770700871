
  .inferences_Content {
    display: flex;
    padding: 1em;
    width: 100%;
  }

.bar_Chart {
    background-color: #1c1c1c;
    flex-wrap: wrap;
    color: #FEFEFE;
    padding: 2rem;
    border-radius: 1rem;    
    height: 450px;
    width: 45%;
    min-width: 350px;
    margin-right: 0.8rem;

}

.pie_Chart {
    flex-wrap: wrap;
    background-color: #1c1c1c;
    color: #FEFEFE;
    padding: 2rem;
    border-radius: 1rem;
    height: 450px;
    width: 45%;
    min-width: 350px;
    

}

.pie_Chart canvas {
    width: 80% !important; /* Ajuste para metade do tamanho original */
    height: 350px !important; /* Ajuste para metade do tamanho original */
    font-size: 16px;
  }
  
  .bar_Chart canvas {
    width: 80% !important; /* Ajuste para metade do tamanho original */
  }

.pie_Chart > p, .bar_Chart > p {
    background-color: #242424;
    padding: 0.5rem;
    border-radius: 1rem;
    width: 40%;
   
}

@media (max-width: 1000px) {

    .inferences_Content {
        padding: 0;
        display: flex;
        flex-wrap: wrap;
    }
    .pie_Chart {
        flex-direction: column;
        width: 100%;
        padding: 0;
    }

    .bar_Chart {
        flex-direction: column;
        flex-wrap: wrap;
        width: 100%;
        padding: 0;
        margin-bottom: 0.2rem;
    }
}