.card-devices {
  display: flex;
  flex-direction: column;
  background-color: #1c1c1c;
  color: #d3d3d3;
  width: 45%;
  min-width: 180px;
  border-radius: 1rem;
  padding: 2rem;

}

.card-devices p {
  background-color: #242424;
  padding: 0.5rem;
  border-radius: 1rem;
  width: 45%;
}




.devices_Content {
  color: #888888;
  width: 100%;
  background: #1c1c1c;
  padding: 1rem;
  border-radius: 1rem;
  overflow-y: auto; /* Adicionado para rolagem horizontal em telas menores */
}

.devices_Actions {
  display: flex;
  justify-content: flex-end; /* Corrigido para alinhar à direita */
  margin-bottom: 20px;
}

.devices_Actions button {
  padding: 10px 20px;
  margin: 5px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  color: #fff;
  transition: background-color 0.3s ease;
}

.start_Button {
  background-color: #28a745;
}

.finish_Button {
  background-color: #dc3545;
}

.edit_Button {
  background-color: #ffc107;
}

.delete_Button {
  background-color: #343a40;
}

.devices_Table {
  width: 100%;
  margin-bottom: 20px;
}

.devices_Table th,
.devices_Table td {
  padding: 1rem;
  text-align: left;
  border-bottom: 0.1rem dashed #6e6e6e;
  white-space: nowrap; /* Evita que o texto quebre em várias linhas */
  /*overflow: hidden;
  text-overflow: ellipsis; /* Mostra "..." para texto que transborda */
}


.devices_Selector {
  width: 20px;
  color: #fff;
}

/* Estilos para status */
.status-online .online-text {
  color: #34C236;
  border: 0.5px solid #34C236;
  padding: 0.5rem;
  border-radius: 1.5rem;
  font-size: 0.8rem;
}

.status-offline .offline-text {
  color: #C24E34;
  border: 0.5px solid #C24E34;
  padding: 0.5rem;
  border-radius: 1.5rem;
  font-size: 0.8rem;
}

.status-streaming .streaming-text {
  color: #17a2b8;
}



/* Estilos para mensagens de feedback */
.loading-message,
.error-message,
.success-message {
  margin: 20px 0;
  padding: 10px 20px;
  border-radius: 4px;
  text-align: center;
}

.loading-message {
  background-color: var(--primary-blue);
  color: #fff;
}

.error-message {
  background-color: #dc3545;
  color: #fff;
}

.success-message {
  background-color: #28a745;
  color: #fff;
}

.data_Button {
  justify-content: center;
  justify-items: center;
  color: #364AB2;
  border-radius: 0.5rem;
  border: 1px solid #364AB2;
  padding: 0.5rem;
  text-decoration: none;
  width: 30%;

}


/* Media query para dispositivos menores */
@media (max-width: 768px) {
  .devices {
      margin-left: 0;
      padding: 10px;
      width: 100%
  }

  .devices_Content {
      padding: 10px;
  }

  .devices_Actions {
      justify-content: center;
  }

  .devices_Actions button {
      padding: 8px 16px;
      font-size: 12px;
  }

  .devices_Table th,
  .devices_Table td {
      padding: 10px;
      font-size: 12px;
  }

  .pagination button {
      padding: 8px 12px;
      font-size: 12px;
  }
}

  

@media (max-width: 1000px) {

  .card-devices {
      padding: 0;
      display: flex;
      flex-wrap: wrap;
      width: 100%;
  }

}