

.card-grupo {
  display: flex;
  flex-direction: column;
  background-color: #1c1c1c;
  color: #FFFFFF;
  width: 45%;
  min-width: 180px;
  border-radius: 1rem;
  padding: 2rem;

}

.card-grupo p {
  background-color: #242424;
  padding: 0.5rem;
  border-radius: 1rem;
  width: 45%;
}

