.containersofia {
    display: flex;
    flex-direction: column;
    background: linear-gradient(145deg, #1C1C1C, #001D42);
    width: 20%;
    height: 280px;
    min-width: 300px;
    text-align: center;
    justify-content: center;
    align-items: center;
    border-radius: 1rem;
    padding: 1rem; 
    margin: 0.5rem;
}

:root {
    --side: 160px;
    --l: 100px;
    --num: 10;
    --dur: 10s;
    overflow-x: hidden;
}


.view {
    position: relative;
    width: var(--side);
    height: var(--side);
    perspective: 400px;
    margin-top: -70px; /* Ajusta a posição do orbe */
}

.plane {
    width: var(--side);
    height: var(--side);
    transform-style: preserve-3d;
}

.plane.main {
    position: absolute;
    top: 0;
    left: 0;
    margin: auto;
    transform: rotateX(60deg) rotateZ(-30deg);
    animation: rotate 20s infinite linear;
}

.circle {
    width: var(--side);
    height: var(--side);
    position: absolute;
    transform-style: preserve-3d;
    border-radius: 80%;
    box-sizing: border-box;
    box-shadow: 0 0 10px #25347B, inset 0 0 60px #25347B;
    transition: transform 1s ease;
}

.circle::before,
.circle::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 10%;
    height: 10%;
    border-radius: 100%;
    background: #25347B;
    box-sizing: border-box;
    box-shadow: 0 0 10px 2px #25347B;
}

.circle::before {
    transform: translateZ(calc(var(--l) * -1));
}

.circle::after {
    transform: translateZ(var(--l));
}

/* Posições iniciais dos círculos */
.circle:nth-child(1) {
    transform: rotateZ(calc(1 * 72deg)) rotateX(63.435deg);
}

.circle:nth-child(2) {
    transform: rotateZ(calc(2 * 72deg)) rotateX(63.435deg);
}

.circle:nth-child(3) {
    transform: rotateZ(calc(3 * 72deg)) rotateX(63.435deg);
}

.circle:nth-child(4) {
    transform: rotateZ(calc(4 * 72deg)) rotateX(63.435deg);
}

.circle:nth-child(5) {
    transform: rotateZ(calc(5 * 72deg)) rotateX(63.435deg);
}

.circle:nth-child(6) {
    transform: rotateZ(calc(6 * 72deg)) rotateX(63.435deg);
}

/* Animação das novas posições */
.move-circles .circle:nth-child(1) {
    transform: rotateZ(calc(1 * 60deg)) rotateX(60deg) translateY(60px);
}

.move-circles .circle:nth-child(2) {
    transform: rotateZ(calc(2 * 60deg)) rotateX(70deg) translateY(40px);
}

.move-circles .circle:nth-child(3) {
    transform: rotateZ(calc(3 * 60deg)) rotateX(80deg) translateY(-110px);
}

.move-circles .circle:nth-child(4) {
    transform: rotateZ(calc(4 * 60deg)) rotateX(90deg) translateY(-220px);
}

.move-circles .circle:nth-child(5) {
    transform: rotateZ(calc(5 * 60deg)) rotateX(100deg) translateY(120px);
}

.move-circles .circle:nth-child(6) {
    transform: rotateZ(calc(6 * 60deg)) rotateX(110deg) translateY(20px);
}

@keyframes rotate {
    0% {
        transform: rotateX(0) rotateY(0) rotateZ(0);
    }
    100% {
        transform: rotateX(360deg) rotateY(360deg) rotateZ(360deg);
    }
}

/* Estilo do botão do microfone */
.microphone-button {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #25347B;
    border: none;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    cursor: pointer;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    color: white;
    transition: background-color 0.3s ease;
}

/* Cor do botão ao ser clicado e durante a gravação */
.microphone-button.recording {
    background-color: #FF6347; /* Vermelho */
}

.microphone-button.clicked {
    background-color: #545559;
}

/* Cor temporária ao clicar no botão */
.microphone-button.active {
    background-color: #00FF00; /* Verde */
    animation: colorChange 5s forwards;
}

@keyframes colorChange {
    0% {
        background-color: #00FF00; /* Verde */
    }
    100% {
        background-color: #25347B; /* Azul */
    }
}

.dashboard_Launch {
    padding-left: 6rem;
    padding-right: 1rem;
    background-color: #131313;
    height: 100vh;
}

.container {
    display: flex;
    flex-direction: initial;
    flex-wrap: wrap; 
    width: 100%;
    padding: 1rem;
}

.data_Profile {
    display: flex;
    flex-direction: column;
    background: linear-gradient(145deg, #1C1C1C, #001D42);
    color: #364AB2;
    text-align: center;
    align-items: center;
    width: 20%;
    height: 250px;
    border-radius: 1rem;
    padding: 2rem;
    margin: 0.5rem;
}

.container_imagem_profile {
    width: 100%;
    text-align: center;
    align-items: center;
    justify-content: center;
}

.profile_Image {
    width: 40px;
    height: 40px;
    background-color: #fff;
}

.data_Name {
    text-align: center;
    justify-content: center;
    align-items: center;
    color: #FFFFFF;
    margin-top: 2rem;
}

.data_Info {    
    flex-direction: row;
    text-align: center;
    align-items: center;
    color: #FFFFFF;
    font-weight: 200;
    justify-content: center;
    margin-bottom: 2rem;
}

.data_Button {
    text-align: center;
    color: #364AB2;
    border-radius: 0.5rem;
    border: 1px solid #364AB2;
    padding: 0.5rem;
    text-decoration: none;
    width: 40%;
}

.containersofia {
    position: relative; /* Permite posicionar elementos dentro desse contêiner */
    display: flex;
    flex-direction: column;
    background: linear-gradient(145deg, #1C1C1C, #001D42);
    width: 20%;
    height: 280px;
    min-width: 300px;
    text-align: center;
    justify-content: center;
    align-items: center;
    border-radius: 1rem;
    padding: 1rem; 
    margin: 0.5rem;
}

.sofia {
    width: 100%;
    height: 240px;
    object-fit: cover;
    margin-bottom: 1rem;
}

.microphone-button {
    position: absolute; /* Faz com que o botão seja posicionado sobre o orbe */
    bottom: 10px; /* Ajuste conforme necessário */
    left: 50%;
    transform: translateX(-50%);
    background-color: #25347B;
    border: none;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    cursor: pointer;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    color: white;
    transition: background-color 0.3s ease;
    z-index: 10; /* Garante que o botão fique acima do orbe */
}

.microphone-button.recording {
    background-color: #FF6347;
}

.microphone-button.active {
    background-color: #00FF00;
    animation: colorChange 5s forwards;
}

@keyframes colorChange {
    0% {
        background-color: #00FF00;
    }
    100% {
        background-color: #25347B;
    }
}

.data_Plan {
    display: flex;
    flex-direction: column;
    background: linear-gradient(145deg, #1C1C1C, #001D42);
    color: #FFFFFF;
    width: 20%;
    min-width: 180px;
    height: 280px;
    border-radius: 1rem;
    padding: 1rem;
    align-items: center;
    margin: 0.5rem;
}

.data_Plan > h3 {
    font-weight: 400;
}

.data_Plan > p {
    font-size: 0.8rem;
    font-weight: 200;
}

.data_Plan > button {
    margin-bottom: 0.5rem;
    text-align: center;
    background-color: #2AEAB8;
    border: none;
    width: 30%;
    border-radius: 1rem;
    font-size: 0.8rem;
    font-weight: 600;
}

.infos {
    display: flex;
    flex: 1;
    flex-direction: row;
    background: linear-gradient(145deg, #1C1C1C, #001D42);
    opacity: 1;
    color: #364AB2;
    width: 40%;
    min-width: 220px;
    height: 280px;
    border-radius: 1rem;
    padding: 1rem;
    margin: 0.5rem;
}

.alertas {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    width: 80%;
    padding: 2rem;
}

.alerta {
    display: flex;
    justify-content: flex-start;
    position: relative;
    list-style-type: none;
    background-color: #1C1C1C;
    color: #FFFFFF;
    font-weight: 500;
    border-left: #aeb411 0.2rem solid;
    padding: 1rem;
    text-align: center;
    margin-bottom: 1rem;
}

/* Tablet */
@media only screen and (max-width: 768px) {
    .dashboard_Launch {
        padding-left: 1rem;
        padding-right: 1rem;
        height: 100%;
    }

    .data_Content {
        width: 90%;
    }

    .data_Profile {
        width: 90%;
    }

    .data_Plan {
        width: 90%;
    }

    .containersofia {
        width: 90%;
        height: auto;
    }

    .sofia {
        height: auto;
    }

    .microphone-button {
        width: 50px;
        height: 50px;
        font-size: 20px;
    }

    .infos {
        width: 90%;
    }
}
