.navbar {
    background-color: var(--secondary-blue);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100vh;
    position: fixed;
    left: 0px;
    padding: 1rem;
    z-index: 100;
    border-right: 1px solid #364AB2;
}
.navbar_Profile {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px; /* Tamanho do círculo */
    height: 40px; /* Tamanho do círculo */
    background-color: var(--secondary-color);
    border-radius: 50%; /* Torna o elemento um círculo */
    overflow: hidden; /* Garante que a imagem não ultrapasse os limites do círculo */
}

.navbar_Content {
    display: flex;
    height: 90%;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin-top: 2rem;
}

.profile_Image {
    width: 100%; /* Define a largura da imagem como 100% do espaço disponível */
    height: 100%; /* Define a altura da imagem como 100% do espaço disponível */
    object-fit: cover; /* Garante que a imagem cubra completamente o contêiner */
    border-radius: 50%; /* Torna a imagem circular */
}
.navbar_Items {
    display: flex;
    align-items: center;
    gap: 1rem;
    justify-content: space-between;
    flex-direction: column;
}

.navbar_Control {
    display: flex;
    align-items: center;
    gap: 1rem;
    justify-content: space-between;
    flex-direction: column;
}

.navbar_Control > button {
    background-color: var(--primary-blue);
    border: none;
    padding: 0.8rem;
    display: flex;
    align-items: center;
    border-radius: 0.5rem;
    cursor: pointer;
}

.navbar_Item > button {
    background-color: var(--primary-blue);
    cursor: pointer;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
}

.navbar_Item {
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: none;
    border-radius: 4rem;
    transition: background-color 0.3s ease;
    margin-top: 1.6rem;
}

.navbar_Item:hover {
    background-color: var(--primary-blue);
}

.navbar_Settings {
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s ease;
    cursor: pointer;
    border-radius: 0.5rem;
}

.navbar_Settings:hover {
    background-color: var(--primary-blue);
}

.central {
    background-color: var(--central-background);
    height: 100vh;
}

.navbar_Group {
    display: flex;
    gap: 1rem;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.navbar_Group > button {
    border: none;
    padding: 0.8rem;
    display: flex;
    align-items: center;
    border-radius: 2rem;
    cursor: pointer;
    background-color: var(--secondary-blue);
    transition: background-color 0.3s ease;
}

.navbar_Group > button:hover {
    background-color: var(--primary-blue);
}

.icon-home {
    display: block;
    transform: scale(1);
    transition: transform 0.3s ease;
  }
  
  .icon-home-hover {
    display: none;
    transform: scale(1.2); /* Exemplo de escala */
    transition: transform 0.3s ease;
  }
  
  /* Mostrar SVG de hover ao passar o mouse */
  .navbar_Item:hover .icon-home {
    display: none;
    transform: scale(0.8); /* Exemplo de escala reduzida */
  }
  
  .navbar_Item:hover .icon-home-hover {
    display: block;
    transform: scale(1);
  }

  .icon-mapa {
    display: block;
    transform: scale(1);
    transition: transform 0.3s ease;
  }
  
  .icon-mapa-hover {
    display: none;
    transform: scale(1.2); /* Exemplo de escala */
    transition: transform 0.3s ease;
  }
  
  /* Mostrar SVG de hover ao passar o mouse */
  .navbar_Item:hover .icon-mapa {
    display: none;
    transform: scale(0.8); /* Exemplo de escala reduzida */
  }
  
  .navbar_Item:hover .icon-mapa-hover {
    display: block;
    transform: scale(1);
  }

  .icon-camera {
    display: block;
    transform: scale(1);
    transition: transform 0.3s ease;
  }
  
  .icon-camera-hover {
    display: none;
    transform: scale(1.2); /* Exemplo de escala */
    transition: transform 0.3s ease;
  }
  
  /* Mostrar SVG de hover ao passar o mouse */
  .navbar_Item:hover .icon-camera {
    display: none;
    transform: scale(0.8); /* Exemplo de escala reduzida */
  }
  
  .navbar_Item:hover .icon-camera-hover {
    display: block;
    transform: scale(1);
  }

  .icon-devices {
    display: block;
    transform: scale(1);
    transition: transform 0.3s ease;
  }
  
  .icon-devices-hover {
    display: none;
    transform: scale(1.2); /* Exemplo de escala */
    transition: transform 0.3s ease;
  }
  
  /* Mostrar SVG de hover ao passar o mouse */
  .navbar_Item:hover .icon-devices {
    display: none;
    transform: scale(0.8); /* Exemplo de escala reduzida */
  }
  
  .navbar_Item:hover .icon-devices-hover {
    display: block;
    transform: scale(1);
  }

  .icon-sair {
    display: block;
    transform: scale(1);
    transition: transform 0.3s ease;
  }
  
  .icon-sair-hover {
    display: none;
    transform: scale(1.2); /* Exemplo de escala */
    transition: transform 0.3s ease;
  }
  
  /* Mostrar SVG de hover ao passar o mouse */
  .navbar_Item:hover .icon-sair {
    display: none;
    transform: scale(0.8); /* Exemplo de escala reduzida */
  }
  
  .navbar_Item:hover .icon-sair-hover {
    display: block;
    transform: scale(1);
  }














@media only screen and (max-width: 768px) {
    .navbar {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        height: 4.5vh;
        position: fixed;
        bottom: 0px;
        width: 100%;
        padding: 1rem;
    }

    .navbar_Content {
        display: flex;
        flex-direction: row;
        width: 92%;
        align-items: center;
        justify-content: space-between;
    }


    .navbar_Items {
        display: flex;
        align-items: center;
        flex-direction: row;
        gap: 1rem;
        justify-content: space-between;
    }

    .navbar_Control {
        height: 70%;
        display: flex;
        align-items: flex-end;
        justify-content: center;
    }

    .navbar_Group {
        display: flex;
        flex-direction: row;
    }


    .navbar_Logo {
        display: flex;
    }
}