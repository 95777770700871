.map_Info > img {
    display: flex;
    width: 20vh;
}

.map_View {
    height: 100vh;
}

.button_Status {
    position: absolute;
    bottom: 10px;
    left: 10px;
    z-index: 1;
    display: flex;
    gap: 0.5rem;
    padding-left: 5rem; /* Adjust this padding as needed */
}

.button_Status > button {
    background-color: red; /* Fallback color */
    background-color: #364AB2; /* Primary color */
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
    color: #FEFEFE; /* Text color */
    cursor: pointer;
    border: none;
}

.button_Status > button:hover {
    background-color: #5EBB40; /* Secondary color on hover */
}

.button_Status {
    display: flex;
    gap: 0.5rem;
    padding-left: 5rem;
}

.button_Status > button {
    background-color: red;
    padding: 0.5rem;
    border-radius: 0.5rem;
    color: #FEFEFE;
    cursor: pointer;
    border: none;
    background-color: var(--primary-blue);
}

.button_Status > button:hover {
    background-color: var(--secondary-blue);
}

.filter_Buttons {
    display: flex;
    gap: 1rem;
    padding-left: 1rem;
    position:fixed;
    left: 5rem;
    z-index: 10;
    margin-top: 4rem;
}

.filter_Buttons > button {
    /*background-color:  var(--primary-blue);*/
    background-color: #364AB2;
    opacity: 0.667;
    border: none;
    cursor: pointer;
    color: #FEFEFE;
    padding: 0.5rem;
    border-radius: 0.5rem;
    
}

.filter_Buttons > button:hover {
    background-color: #364AB2;
    opacity: 1;
    /*background-color: var(--secondary-blue);*/
}

.filter_Devices > button {
    background-color: #FEFEFE;
    border: none;
    border-radius: 0.2rem;
    padding: 11px;
    cursor: pointer;
    z-index: 1000;
    position: fixed;
    right: 10px;
    top: 4rem;
}

.info_Infinite {
    z-index: 100;
    display: grid; /* Changed to grid layout */
    position: fixed;
    top: 50px;
    padding-left: 6rem;
    gap: 1rem; /* Space between grid items */
    padding: 1rem;

    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr)); /* Define grid columns */
}


.Info_Camera > p {
    margin-top: 10px;
    margin-bottom: 5px;
    font-weight: 300;
    font-size: 0.8rem;
}

.Info_Camera {
    position: absolute;
    width: 200px; /* Ajuste conforme necessário */
    height: 150px; /* Ajuste conforme necessário */
    padding: 0;
    z-index: 10;
}

.Info_Camera video {
    width: 100%;
    height: 100%;
}

.close-button {
    position: absolute;
    top: -20px;
    right: 2px;
    background: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    padding: 4px;
    cursor: pointer;
}




@media only screen and (max-width: 768px) {
    .map_View {
        height: 94vh;
    }

    .button_Status {
        margin-bottom: 4rem;
        padding-left: 0rem;
    }

    .filter_Buttons {
        display: flex;
        gap: 1rem;
        left: 0rem;
        position:fixed;
        z-index: 10;
        bottom: 6rem;
    }
}
